import React from 'react';
import { Link } from 'react-router-dom';
import './Index.scss';
import { Container, Row, Col } from 'react-bootstrap';

import aboutData from '../../data/about.json';

class Index extends React.Component {

    render() { 
        return ( 
          <div className="about-experience-area pb-120">
            <Container>
              <Row>
                <Col lg="5">
                  <div className="experience-item">
                    <span>{aboutData.experience} Years Of Experience</span>
                    <h2 className="title">{aboutData.details.header}</h2>
                  </div>
                  {/* experience item */}
                </Col>
                <Col lg="6" className="offset-lg-1">
                  <div className="experience-item">
                    {
                      aboutData.details.paragraphs.map((item, idx) => (
                        <p key={`about-details-paragraph-${idx}`}>{item}</p>
                      ))
                    }
                  </div>
                  {/* experience item */}
                </Col>
              </Row>
              <Row className="justify-content-center">
                <div
                  className="col-lg-4 col-md-6 col-sm-8 wow fadeInUp"
                  data-wow-duration=".5s"
                  data-wow-delay=".2s"
                >
                  <div className="single-experience mt-30">
                    <img src="assets/images/experience-1-1.jpg" alt="" />
                    <div className="experience-overlay">
                      <h5 className="title">
                        <a href="https://github.com/Pnd-admin" target="_blank"> Github Repo </a>
                      </h5>
                      <i className="fal fa-laptop-code" />
                    </div>
                  </div>
                  {/* single experience */}
                </div>
                <div
                  className="col-lg-4 col-md-6 col-sm-8 wow fadeInUp"
                  data-wow-duration="1s"
                  data-wow-delay=".4s"
                >
                  <div className="single-experience mt-30">
                    <img src="assets/images/experience-2-2.jpg" alt="" />
                    <div className="experience-overlay">
                      <h5 className="title">Contact</h5>
                      <p>
                        <a href="mailto:wizard@pndlabs.com" target="_blank">Email Us</a>
                      </p>
                      <i className="fal fa-headphones-alt" />
                    </div>
                  </div>
                  {/* single experience */}
                </div>
              </Row>
              {/* row */}
            </Container>
            {/* container */}
          </div>
         );
    }
}
 
export default Index;
