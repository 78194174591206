import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Index1 from './pages/home/index';
import AboutPage from './pages/about';
import ContactPage from './pages/contact';
import BlogGrid from './pages/blog/blog-grid';
import BlogDetails from './pages/blog/blog-details';


import NoMatch from './pages/errors/no-match';
import ComingSoon from "./pages/coming-soon";

class App extends React.Component {

	render() {
		return (
			<BrowserRouter>
				<Routes>
				  <Route index element={<Index1/>}/>
				  <Route path="index" element={<Index1/>}/>
				  <Route path="about" element={<AboutPage/>}/>
				  <Route path="coming-soon" element={<ComingSoon/>}/>
				  <Route path="blog-grid" element={<BlogGrid/>}/>
				  <Route path="blog-details" element={<BlogDetails/>}/>
				  <Route path="contact" element={<ContactPage/>}/>
				  <Route element={<NoMatch/>} />
				</Routes>
			<ToastContainer />
		</BrowserRouter>
		 );
	}
}
 
export default App;
