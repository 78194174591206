import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

class Footer extends React.Component {

  render() { 
      return ( 
        <React.Fragment>
        <footer className="footer-area footer-area-2 footer-area-1 bg_cover" style={{backgroundImage: 'url(assets/images/footer-bg.jpg)'}}>
          <div className="footer-overlay">
            <Container className="position-relative">
              <Row>
                <Col lg="12">
                  <div className="footer-copyright">
                    <p>Copyright By@ <span>PND Labs</span> - {(new Date().getFullYear())}</p>
                  </div> {/* footer copyright */}
                </Col>
              </Row> {/* row */}
            </Container> {/* container */}
          </div>
        </footer>
        {/*====== BACK TO TOP ======*/}
        <div className="back-to-top back-to-top-2">
          <a  href="#backTotop">
            <i className="fas fa-arrow-up" />
          </a>
        </div>
        {/*====== BACK TO TOP ======*/}

      </React.Fragment>
    );
  }
}
 
export default Footer;
