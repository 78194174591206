import React, {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import './index.scss';
import axios from 'axios';
import App from './App';
import * as serviceWorker from './serviceWorker';

//axios.defaults.baseURL = 'hhttp://localhost:3000/api/';
//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const root = createRoot(document.getElementById('root'));
root.render(
    <StrictMode>
        <App />
    </StrictMode>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
