import React from 'react';
//import { toast } from 'react-toastify';
import Header from '../../layouts/partials/header/Index';
import Footer from '../../layouts/partials/footer/index';

import Banner from '../../componets/banner/Index';

class Index1 extends React.Component {
    render() { 
        return ( 
            <>
                <Header />
                <Banner/>
                <Footer />
            </>
         );
    }
}
 
export default Index1;
