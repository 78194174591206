import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../layouts';
import { Container, Row, Col } from 'react-bootstrap';
import blogs from '../../data/blogs.json';

class BlogGrid extends React.Component {
    render() {
        return (           
          <Layout>
            <div className="page-title-area">
              <Container>
                <Row>
                  <Col lg="12">
                    <div className="page-title-item text-center">
                      <h2 className="title">Articles</h2>
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link to={"/"}>Home </Link>
                          </li>
                          <li className="breadcrumb-item active" aria-current="page">
                          Blogs
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="blog-grid-area pt-90 pb-120">
              <Container>
                <Row className="justify-content-center">
                  {
                    blogs.blogs.map((blog, index) => (
                        <Col lg="4" md="6" sm="8">
                          <div className="blog-grid-item mt-30">
                            <img src={blog.image} alt="" />
                            <div className="blog-grid-overlay">
                              <span>{blog.tags}</span>
                              <Link to={`/blog-details?id=${blog.id}`}>
                                <h5 className="title" style={blog.titleColor? {'color': blog.titleColor}: undefined}>
                                  {blog.title}
                                </h5>
                              </Link>
                              <ul>
                                <li>
                                  <i className="fal fa-calendar-alt" />
                                  <span>{blog.date}</span>
                                </li>
                                <li>
                                  <i className="fal fa-heart" />
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Col>
                    ))
                  }
                </Row>
              </Container>
            </div>
          </Layout>
         );
    }
}
 
export default BlogGrid;
