import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebookF,
    faTwitter,
} from '@fortawesome/free-brands-svg-icons';


class SocialLink extends Component {
    render() { 
        return ( 
            <ul>
                <li>
                    <a href="https://www.facebook.com/profile.php?id=100093065033994" target="__blank">
                        <FontAwesomeIcon icon={faFacebookF}/>
                    </a>
                </li>
                <li>
                    <a href="https://twitter.com" target="__blank">
                        <FontAwesomeIcon icon={faTwitter}/>
                    </a>
                </li>
            </ul>
         );
    }
}
 
export default SocialLink;
