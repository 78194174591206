import React from 'react';
import { Link } from "react-router-dom";
import { Nav } from 'react-bootstrap';

class PartialNav extends React.Component {
    render() { 
        return ( 
            <>
            <Nav className="mr-auto">
              <li className="nav-item">
              <Link className="nav-link" to={'/'}>Home</Link>
            </li>
            <li className="nav-item">
                  <Link className="nav-link" to={'/about'}>About</Link>                
            </li>
            <li className="nav-item">
                  <Link className="nav-link"  to={'/'}>Pages</Link>
              <ul className="sub-menu">
                <li>
                  <Link to={'/coming-soon'}>Github</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link"  to={'/contact'}>Contact</Link>
                </li>
              </ul>
              {/* sub menu */}
            </li>
            <li className="nav-item">
              <Link  className="nav-link" to={'/blog-grid'}>Blog</Link>
            </li>
          </Nav>
            </>
         );
    }
}
 
export default PartialNav;
