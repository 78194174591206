import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import Layout from '../../layouts';
import { Container, Row, Col } from 'react-bootstrap';
import blogs from '../../data/blogs.json';
import BlogGrid from "./blog-grid";
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
export default () => {
  const query = useQuery();
  const id = query.get('id');
  if(!id ){
    return <BlogGrid />
  }
  const blog = blogs.blogs.filter(blog => blog.id === id);
  const blogPage = {...blog[0]};
        return (
          <Layout>
            <div className="page-title-area">
              <Container>
                <Row>
                  <Col lg="12">
                    <div className="page-title-item text-centBlogDetailser">
                      <h2 className="title">{blogPage.title}</h2>
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link to={"/"}>Home </Link>
                          </li>
                          <li className="breadcrumb-item active" aria-current="page">
                            <Link to={"/blog-grid"}>Blogs </Link>
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="blog-standard-area">
              <Container>
                <Row className="justify-content-center">
                  <Col lg="12">
                    <div className="blog-standard">
                      <div className="single-blog-standard  mt-30">
                        <div className="blog-dteails-content blog-border">
                          <div className="blog-details-bath">
                            <span>{blogPage.tags}</span>
                            <h2 className="title">
                              {blogPage.title}
                            </h2>
                            <ul>
{/*                              <li>
                                <i className="fal fa-eye" /> 232 Views
                              </li>
                              <li>
                                <i className="fal fa-comments" /> 35 Comments
                              </li>*/}
                              <li>
                                <i className="fal fa-calendar-alt" /> {blogPage.date}
                              </li>
                            </ul>
                            <p>
                              {blogPage.brief}
                            </p>
                            <a href={blogPage.link} className="main-btn" target={"_blank"}>Read More</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
     {/*           <BlogDetailsSideBar/>*/}
            </Row>
          </Container>
        </div>
        </Layout>
         );
}
